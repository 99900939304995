import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import style from '../chatBody/chatbody.module.scss';

interface MeetingCardProps {
  children?: ReactNode;
  response: any[];
}

export default function MeetingCard({ response }: MeetingCardProps) {
    const handleCardClick = (actionLink: string) => {
        const width = 550; // Set the desired width of the new window
        const height = 500; // Set the desired height of the new window
        const left = window.innerWidth / 2 - width / 2;
        const top = window.innerHeight / 2 - height / 2;
        window.open(actionLink, "_blank", `width=${width},height=${height},top=${top},left=${left}`);
      };

  const responseArray = Array.isArray(response) ? response : [response];

  return (
    <>
      {responseArray.map((resp, i) => {  
        // console.log(resp?.title)     
        return (
          <Card
            sx={{
              display: 'flex',
              width: 300,
              marginBottom: 2,
              height: 'auto',
              alignItems: 'center',
              padding: 0.5,
              background:'#f3f0f0'
            }}
            key={i}
            className={`${style.botCard} ${style.fadeIIn}`}
            
          >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {/* {resp?.image?.src?.rawUrl && (
                <CardMedia
                  component="img"
                  sx={{ width: 35, height: 'fit-content' }}
                  image={resp?.image?.src?.rawUrl}
                  alt="no preview"
                />
              )} */}
              <CardContent sx={{
                display: 'flex',
                flexDirection:"row",
                justifyContent:"start",
                gap:"10px"
              }} id="cardContentLinkTitle" className={style.meetingCard}>
                     {/* <Typography onClick={() => index===1?handleCardClick(resp?.actionLink):""} gutterBottom variant="h6" component="div" className={style.meetingCardTitle}>
                      {resp?.title}
                      </Typography> */}
                <div dangerouslySetInnerHTML={{ __html: resp?.title }} onClick={(e:any) => {
                          // Check if the click target is an anchor tag
                  if (e.target.tagName.toLowerCase() === 'a') {
                  e.preventDefault(); // Prevent default anchor tag behavior
                  handleCardClick(resp.actionLink); // Call custom function
                }
              }}></div>
              </CardContent>
            </Box>
          </Card>
        );
      })}
    </>
  );
}
