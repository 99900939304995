import { Form, Formik, useFormikContext } from 'formik';
import CustomCheckbox from '../custom-checkbox';
import style from './sryle.module.scss';
import { useContext, useEffect } from 'react';
import { MultiSelectContext } from '../../context/multiSelectProvider';

export interface FormValues {
    [key: string]: any; // Adjust this to match your actual form structure
}

interface Option {
    id: string;
    text: string;
}

interface CustomMultiSelectProps {
    options: { option: Option[] };
}

// CustomMultiSelect component that uses Formik context
function CustomMultiSelect({ options }: CustomMultiSelectProps) {
    const { handleSubmit, values } = useFormikContext<FormValues>();
    const { isValid, updateValue } = useContext(MultiSelectContext);

    useEffect(() => {
        if (values) {
            // console.log('Values:', values);
            Object.keys(values).forEach((key) => {
            // console.log('key:', key);
            // console.log('values[key]:', values[key]);

                if (values[key]) {
                   console.log('kesdady:', values[key]);

                    updateValue(true);
                } else {
                    delete values[key];
                }
            });
            if(Object.keys(values)?.length===0){
              updateValue(false);
            }
        } else {
            updateValue(false);
        }
        // console.log('Values: last', values);

    }, [values]);

    useEffect(() => {
        // console.log('Is Valid:', isValid);
    }, [isValid]);

    async function submitHandler(e: React.FormEvent) {
        e.preventDefault();
        await updateValue(false);
        handleSubmit();
    }

    return (
        <Form onSubmit={submitHandler} className={style.FormContainer}>
            {options.option.map((option) => (
                <CustomCheckbox
                    key={option.id}
                    id={option.id}
                    label={option.text}
                    name={option.text} // Ensure unique names for checkboxes
                />
            ))}
            <button className={style.SubmitBtn} disabled={!isValid} type="submit">
                Submit
            </button>
        </Form>
    );
}

// Main component wrapping CustomMultiSelect with Formik
interface MyFormComponentProps {
    options: { option: Option[] };
    onSubmit: (index: number, apiData: string) => void;
    index: number;
}

export default function MyFormComponent({ options, onSubmit, index }: MyFormComponentProps) {
  function onSubmitHandler(values: FormValues, { resetForm }: { resetForm: () => void }) {
    if (values) {
        const apiData = Object.keys(values).join('|');
        onSubmit(index, apiData);
        // resetForm(); // Reset the form after submission
    }
}

    return (
        <Formik
            initialValues={{}} // Set your initial values here
            onSubmit={onSubmitHandler}
        >
            <CustomMultiSelect options={options} />
        </Formik>
    );
}
