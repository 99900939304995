import React, { createContext, useState, ReactNode } from 'react';

// Define the shape of the context value
interface MultiSelectContextType {
    isValid: boolean;
    updateValue: (data: boolean) => void;
}

// Create the context with a default value
export const MultiSelectContext = createContext<MultiSelectContextType>({
    isValid: false,
    updateValue: () => {}, // Default implementation for the updateValue function
});

// Define the props for the provider
interface MultiSelectProviderProps {
    children: ReactNode;
}

// Create the provider component
export const MultiSelectProvider: React.FC<MultiSelectProviderProps> = ({ children }) => {
    const [isValid, setIsValid] = useState<boolean>(false);

    const updateValue = (data: boolean) => {
        setIsValid(data);
    };

    return (
        <MultiSelectContext.Provider value={{ isValid, updateValue }}>
            {children}
        </MultiSelectContext.Provider>
    );
};
