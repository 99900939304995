// import React, { useContext, useState } from 'react';
// import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Typography from '@mui/material/Typography';
// import { ReactNode } from 'react';
// import style from '../chatBody/chatbody.module.scss';
// import ResponseRenderer from '../repsonseRenderer'; // Adjust the path accordingly
// import { IframeContext } from "../../iFrameContext";


// interface InfoTextProps {
//   children?: ReactNode;
//   response: any[];
// }

// export default function InfoText({ response }: InfoTextProps) {
//   const [expandedCardIndices, setExpandedCardIndices] = useState<number[]>([]);
//   const { iframeData } = useContext(IframeContext);


//   const toggleExpand = (index: number) => {
//     setExpandedCardIndices((prevIndices) => {
//       if (prevIndices.includes(index)) {
//         return prevIndices.filter((i) => i !== index);
//       } else {
//         return [...prevIndices, index];
//       }
//     });
//   };

//   const truncateText = (text: string, maxWords: number) => {
//     const words = text.split(' ');
//     const truncatedWords = words.slice(0, maxWords);
//     const truncatedText = truncatedWords.join(' ');
//     return truncatedText;
//   };

//   const renderSubtitle = (subtitle: string, isExpanded: boolean, index: number) => {
//     const maxWords = 20; // Adjust the maximum number of words as per your requirement
//     const truncatedSubtitle = truncateText(subtitle, maxWords);
//     const showReadMore = subtitle.length > truncatedSubtitle.length;

//     if (isExpanded) {
//       return (
//         <>
//           <ResponseRenderer htmlContent={subtitle} />
//           {showReadMore && (
//             <span
//               style={{ color: `${iframeData?.infoTextReadMoreLess}`, cursor: 'pointer',marginTop:'3px' }}
//               onClick={() => toggleExpand(index)}
//             >View less
//             </span>
//           )}
//         </>
//       );
//     } else {
//       return (
//         <>
//           <ResponseRenderer htmlContent={truncatedSubtitle} />
//           {showReadMore && (
//             <span
//               style={{ color: `${iframeData?.infoTextReadMoreLess}`, cursor: 'pointer' }}
//               onClick={() => toggleExpand(index)}
//             >View more
//             </span>
//           )}
//         </>
//       );
//     }
//   };

//   console.log("response in infotext = ",response[0]?.title);

//   if(response[0]?.title == "Feedback Time!"){

//   }

//   return (
//     <>
//       {response.map((resp, i) => {
//         const isExpanded = expandedCardIndices.includes(i);
//         return (
//           <Card
//             sx={{
//               display: 'flex',
//               background: '#f3f0f0',
//               width: 300,
//               marginBottom: 2,
//               height: 'auto',
//               alignItems: 'center',
//               padding: 0.5,
//             }}
//             key={i}
//             className={`${style.infoTextMain} ${style.fadeIIn}`}
//           >
//             <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
//               {resp?.image?.src?.rawUrl && (
//                 <CardMedia
//                   component="img"
//                   sx={{ width: 35, height: 'fit-content' }}
//                   image={resp?.image?.src?.rawUrl}
//                   alt="no preview"
//                 />
//               )}
//               <CardContent>
//                 <Typography gutterBottom variant="h5" component="div" className={style.infoTextTitle}>
//                   {resp?.title}
//                 </Typography>
//                 <Typography component="div" className={style.infoTextSubtitle}>
//                   {renderSubtitle(resp?.subtitle, isExpanded, i)}
//                 </Typography>
//               </CardContent>
//             </Box>
//           </Card>
//         );
//       })}
//     </>
//   );
// }


// import React, { useContext, useState } from 'react';
// import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Typography from '@mui/material/Typography';
// import { ReactNode } from 'react';
// import StarIcon from '@mui/icons-material/Star';
// import StarBorderIcon from '@mui/icons-material/StarBorder';
// import style from '../chatBody/chatbody.module.scss';
// import ResponseRenderer from '../repsonseRenderer'; // Adjust the path accordingly
// import { IframeContext } from "../../iFrameContext";

// interface InfoTextProps {
//   children?: ReactNode;
//   response: any[];
// }

// export default function InfoText({ response }: InfoTextProps) {
//   const [expandedCardIndices, setExpandedCardIndices] = useState<number[]>([]);
//   const [rating, setRating] = useState<number | null>(null);
//   const { iframeData } = useContext(IframeContext);

//   const toggleExpand = (index: number) => {
//     setExpandedCardIndices((prevIndices) => {
//       if (prevIndices.includes(index)) {
//         return prevIndices.filter((i) => i !== index);
//       } else {
//         return [...prevIndices, index];
//       }
//     });
//   };

//   const truncateText = (text: string, maxWords: number) => {
//     const words = text.split(' ');
//     const truncatedWords = words.slice(0, maxWords);
//     const truncatedText = truncatedWords.join(' ');
//     return truncatedText;
//   };

//   const renderSubtitle = (subtitle: string, isExpanded: boolean, index: number) => {
//     const maxWords = 20; // Adjust the maximum number of words as per your requirement
//     const truncatedSubtitle = truncateText(subtitle, maxWords);
//     const showReadMore = subtitle.length > truncatedSubtitle.length;

//     if (isExpanded) {
//       return (
//         <>
//           <ResponseRenderer htmlContent={subtitle} />
//           {showReadMore && (
//             <span
//               style={{ color: `${iframeData?.infoTextReadMoreLess}`, cursor: 'pointer', marginTop: '3px' }}
//               onClick={() => toggleExpand(index)}
//             >
//               View less
//             </span>
//           )}
//         </>
//       );
//     } else {
//       return (
//         <>
//           <ResponseRenderer htmlContent={truncatedSubtitle} />
//           {showReadMore && (
//             <span
//               style={{ color: `${iframeData?.infoTextReadMoreLess}`, cursor: 'pointer' }}
//               onClick={() => toggleExpand(index)}
//             >
//               View more
//             </span>
//           )}
//         </>
//       );
//     }
//   };

//   const handleRating = (index: number) => {
//     setRating(index);
//   };

//   const renderStars = () => {
//     return (
//       <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
//         {[1, 2, 3, 4, 5].map((index) => (
//           <Box
//             key={index}
//             sx={{ cursor: 'pointer' }}
//             onClick={() => handleRating(index)}
//           >
//             {index <= (rating ?? 0) ? (
//               <StarIcon sx={{ color: '#FFD700' }} />
//             ) : (
//               <StarBorderIcon sx={{ color: '#FFD700' }} />
//             )}
//           </Box>
//         ))}
//       </Box>
//     );
//   };

//   console.log("response in infotext = ", response[0]?.title);

//   return (
//     <>
//       {response.map((resp, i) => {
//         const isExpanded = expandedCardIndices.includes(i);
//         return (
//           <Card
//             sx={{
//               display: 'flex',
//               background: '#f3f0f0',
//               width: 300,
//               marginBottom: 2,
//               height: 'auto',
//               alignItems: 'center',
//               padding: 0.5,
//             }}
//             key={i}
//             className={`${style.infoTextMain} ${style.fadeIIn}`}
//           >
//             <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
//               {resp?.image?.src?.rawUrl && (
//                 <CardMedia
//                   component="img"
//                   sx={{ width: 35, height: 'fit-content' }}
//                   image={resp?.image?.src?.rawUrl}
//                   alt="no preview"
//                 />
//               )}
//               <CardContent>
//                 <Typography gutterBottom variant="h5" component="div" className={style.infoTextTitle}>
//                   {resp?.title}
//                 </Typography>
//                 <Typography component="div" className={style.infoTextSubtitle}>
//                   {renderSubtitle(resp?.subtitle, isExpanded, i)}
//                 </Typography>
//                 {resp?.title === "Feedback Time!" && renderStars()}
//               </CardContent>
//             </Box>
//           </Card>
//         );
//       })}
//     </>
//   );
// }



import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import style from '../chatBody/chatbody.module.scss';
import ResponseRenderer from '../repsonseRenderer'; // Adjust the path accordingly
import { IframeContext } from "../../iFrameContext";

interface InfoTextProps {
  children?: ReactNode;
  response: any[];
  sessionId: string | null;
  getResponse?:any;
  setFulfillmentTexts?:any;
  startIdleTimer?:any;
}

const ratingValues = ["very bad", "bad", "average", "good", "very good"];
const newStarLabels = [
  { 
    label: "Poor",
    id:1,
    value:"very bad"
  },
  { 
    label: "2",
    id:2,
    value:"bad"
  },
  { 
    label: "3",
    id:3,
    value:"average"
  },
  { 
    label: "4",
    id:4,
    value:"good"
  },
  { 
    label: "Great",
    id:5,
    value:"very good"
  }
]

export default function InfoText({ sessionId, response,getResponse,setFulfillmentTexts,startIdleTimer }: InfoTextProps) {
  const [expandedCardIndices, setExpandedCardIndices] = useState<number[]>([]);
  const [rating, setRating] = useState<number | null>(null);
  const { iframeData } = useContext(IframeContext);

  const toggleExpand = (index: number) => {
    setExpandedCardIndices((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index);
      } else {
        return [...prevIndices, index];
      }
    });
  };
  const responseCallback = (data:any)=>{
    // console.log(data,"newResponseData callback")

    getResponse(data);
    
  }
  const truncateText = (text: string, maxWords: number) => {
    const words = text.split(' ');
    const truncatedWords = words.slice(0, maxWords);
    const truncatedText = truncatedWords.join(' ');
    return truncatedText;
  };

  const renderSubtitle = (subtitle: string, isExpanded: boolean, index: number) => {
    const maxWords = 20; // Adjust the maximum number of words as per your requirement
    let subtitleWithBreaks = subtitle.replace(/\● /g, '<br> ● '); // Replace * with <br>
    // const newSubtitle = subtitleWithBreaks.replace(/\n\n /g, '<br>')
    subtitleWithBreaks = subtitleWithBreaks.replace(/\n /g, '<br>');
    subtitleWithBreaks = subtitleWithBreaks.replace(/\n\n /g, '<br>');
    subtitleWithBreaks = subtitleWithBreaks.replace(/\u25cf /g, '<br> ● ');
    const truncatedSubtitle = truncateText(subtitleWithBreaks, maxWords);
    const showReadMore = subtitle.length > truncatedSubtitle.length;

    if (isExpanded) {
      return (
        <>
          <ResponseRenderer htmlContent={subtitleWithBreaks} />
          {showReadMore && (
            <span
              style={{ color: `${iframeData?.infoTextReadMoreLess}`, cursor: 'pointer', marginTop: '3px' }}
              onClick={() => toggleExpand(index)}
            >
              View less
            </span>
          )}
        </>
      );
    } else {
      return (
        <>
          <ResponseRenderer htmlContent={truncatedSubtitle} />
          {showReadMore && (
            <span
              style={{ color: `${iframeData?.infoTextReadMoreLess}`, cursor: 'pointer' }}
              onClick={() => toggleExpand(index)}
            >
              View more
            </span>
          )}
        </>
      );
    }
  };

  const handleRating = (index: number) => {
    setRating(index);
    const ratingText = ratingValues[index - 1];
    sendRatingToAPI(ratingText);
  };

  const sendRatingToAPI = async (ratingText: string) => {
    // Replace this with your API call logic
    // console.log('Payload sent to API:', payload);
    // startIdleTimer();
    const data = {
      agent_id: process.env.REACT_APP_AGENT_ID,
      session_end: false,
      session_id: sessionId,
      text: ratingText,
      timezone: "Asia/Calcutta",
      translate_code: "en",
    };
    setFulfillmentTexts((prevFulfillmentTexts:any) => [
      ...prevFulfillmentTexts,
      {
        uid: 0,
        text: "Dummy value",
        flag: true,
        isUser: false,
        option: [],
        textLength: 2000
      },
    ]);

    try {
      // console.log("1")
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "voice/webhooks/chatbot",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Request failed with status " + response.status);
      }

      const newResponseData = await response.json();
      responseCallback(newResponseData);
           // console.log("infotext response = ", newResponseData);
    }catch(error){}
  }

  const renderStars = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1,width:"100%",justifyContent:"space-between" }}>
        {}
          {newStarLabels.map((star,index) => (
          <Box
            key={index}
            sx={{ cursor: 'pointer',display:"flex",flexDirection:"column",alignItems:"center" }}
            onClick={() => handleRating(star?.id)}
          >
            {star.id <= (rating ?? 0) ? (
              <StarIcon sx={{ color: '#FFD700',fontSize:"30.07px",fontWeight:400 }} />
            ) : (
              <StarBorderIcon sx={{ color: '#0E497A',fontSize:"30.07px",fontWeight:400 }} />
            )}
            <Typography sx={{
              color:"#C1CDD7",
              fontSize:12,
              fontWeight:500,
              lineHeight:"20px"
            }} align='center'>{star?.label}</Typography>
          </Box>
        ))}
      </Box>
    );
  };

  // console.log("response in infotext = ", response[0]?.title);

  return (
    <>
      {response.map((resp, i) => {
        const isExpanded = expandedCardIndices.includes(i);
        // console.log(resp?.feedback,"resp?.feedback ")
        return (
          <Card
            sx={{
              display: 'flex',
              background: resp?.feedback?'transparent !important':'#0E497A',
              width: 300,
              marginBottom: 2,
              height: 'auto',
              alignItems: 'center',
              padding: 0.5,
            }}
            key={i}
            className={`${style.infoTextMain} ${style.fadeIIn}`}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start',background: resp?.feedback?'transparent':'', }}>
              {/* {resp?.image?.src?.rawUrl && (
                <CardMedia
                  component="img"
                  sx={{ width: 35, height: 'fit-content' }}
                  image={resp?.image?.src?.rawUrl}
                  alt="no preview"
                />
              )} */}
              <CardContent sx={{
              background: resp?.feedback?'#0E497A':'#f1f8fd',              
              borderRadius:resp?.feedback?'0px 10px 10px 10px':''
              }}>
                <Typography gutterBottom variant="h5" component="div" className={resp?.feedback?style.feedbackTitle:style.infoTextTitle}>
                  {resp?.title}
                </Typography>
                <Typography component="div" className={resp?.feedback?style.feedbackSubtitle:style.infoTextSubtitle}>
                  {renderSubtitle(resp?.subtitle, isExpanded, i)}
                </Typography>
              </CardContent>
                {resp?.feedback === true && renderStars()}
            </Box>
          </Card>
        );
      })}
    </>
  );
}

