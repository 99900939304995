// import React, { useContext,useState } from "react";
// import { useMediaQuery } from "react-responsive";
import "./App.css";
import Chatbot from "./Components/chatBot/chatbot";
import { IframeContext, IframeProvider } from "./iFrameContext";
import WebFont from "webfontloader";
// import chatGif from '../src/Images/chatGif.gif'
// import ChatBody from "./Components/chatBody/chatbody";
import { MultiSelectProvider } from "./context/multiSelectProvider";

const App = () => {
  // const deviceType = useMediaQuery({ maxWidth: 520 });
  // const isLessThan480px = useMediaQuery({ maxWidth: 480 });
  // const [isBotClosed, setBotClosed] = useState(false);
  // const { iframeData } = useContext(IframeContext);
  // let contentWindow: boolean = false;

  // const handleBotClick = () => {
  //   console.log(isBotClosed);
    
  //   if (isBotClosed) {
  //     // Bot is closed, so open it and show the button
  //     setBotClosed(p => !p);
  //   } else {
  //     // Bot is open, so close it and hide the button
  //     setBotClosed(p => !p);
  //   }
      
  // };
  

  WebFont.load({
    google: {
      families: ["Mulish:500","Montserrat", "sans-serif"],
    },
  });

  return (
    <IframeProvider>
      <MultiSelectProvider>
        <Chatbot></Chatbot>
      </MultiSelectProvider>
    </IframeProvider>
  );
};

export default App;

