import { Input, Typography } from "@mui/material";
import style from './style.module.scss'
import { Field } from "formik";

interface MultiSelect {
    id?: string;
    value?: boolean;
    label?: string;
    name?: string;
    className?: string;
}

export default function CustomCheckbox(props: MultiSelect) {
    const {
        id,
        label = "",
        name = "",
        className = "",
        ...el
    } = props;
    const date = new Date()
    return (
        <div className={style.checkBox}>
            <Field
                type="checkbox"
                className={`${className} ${style.checkboxInput}`}
                id={`${name}-${id}-${date.toISOString()}`}
                name={`${name}`}
                {...el}
            />
            <label htmlFor={`${name}-${id}-${date.toISOString()}`}>{label}</label>
        </div>
    );
}
