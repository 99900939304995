import style from "./chatheader.module.scss";
import { useContext } from "react";
import { IframeContext } from "../../iFrameContext";
import logo from "../../Images/simechat-logo.png";
import CloseIcon from "@mui/icons-material/Close";
import MinimizeIcon from '@mui/icons-material/Minimize';
import { IconButton } from "@mui/material";
import diaBotAvatar from "../../Images/diaBot.svg"
import minimizeIcon from "../../Images/minimizeNew.svg"
import closeIcon from "../../Images/closeNew.svg"

interface ChildComponentProps {
  handleClose: () => void;
  setFulfillmentTexts:any;
  pauseIdleTimer:any;
  sessionId: string | null;
}

const ChatHeader: React.FC<ChildComponentProps> = ({ handleClose, setFulfillmentTexts, pauseIdleTimer, sessionId }) => {
  const { iframeData } = useContext(IframeContext);  
 
  const handleCloseClick = async () => {
    window.parent.postMessage("message",`*`);
    pauseIdleTimer();
    const data = {
      agent_id:process.env.REACT_APP_AGENT_ID,
      session_id: sessionId,
      text: 'Timeout',
      timezone: "Asia/Calcutta",
      translate_code: "en",
    };

    try{
      // console.log("6")
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "voice/webhooks/chatbot",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Request failed with status " + response.status);
      }
      
    }catch (error) {
      console.error("Error:", error);
    }
    window.location.reload();
    
  };

  const handleMinimizeClick = () => {
    // console.log("inside minimize click");
    window.parent.postMessage("minimizeClick", `*`);
  }
  
  if (!iframeData) {
    return null; // Handle the case when the data is not available yet
  }

  return (
      <div className={style.chatheaderMain}>
        <img src={diaBotAvatar} className={style.diabotLogo} />
    {/* logo */}
        {/* <div className={`${style.box} ${style.chatheaderImg}`}>
          <img src={iframeData?.headerAvatar} alt="Bot Icon" className={style.chatheaderLogo} />
        </div> */}
    {/* simechat name */}
        <div className={`${style.box} ${style.chatheaderText}`}>
          <div className={style.chatMainText}>
            {/* <div className={style.name}>{iframeData?.customername}</div> */}
            {/* <div className={style.status}>Online</div> */}
          </div>
        </div>
        {/* <div className={styles.chatHeaderSpeaker}></div> */}
    {/* minimize icon */}
    <div className={style.headerIcons}>
    <div>
        <IconButton onClick={handleMinimizeClick} className={`${style.box} ${style.chatClose}`}>
          <div className={style.customIconButton}>
            {/* <MinimizeIcon /> */}
            <img src={minimizeIcon} />
          </div>
        </IconButton>
    </div>
    {/* Close icon */}
        <div>
        <IconButton onClick={handleCloseClick} className={`${style.box} ${style.chatClose}`}>
          <div className={style.customIconButton}>
            {/* <CloseIcon /> */}
            <img src={closeIcon} />
          </div>
        </IconButton>
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;
